<template>
  <div style="background-color: #faf9f9">
    <Myhead :nav="4"></Myhead>
    <div class="container">
      <div class="router">
        <router-link class="routerLink" to="/">{{Language.knowledgeDetail.home}}</router-link> >
        <router-link class="routerLink" to="/resources/blog" v-if="tag">{{Language.knowledgeDetail.Our_testing_capabilities}}</router-link>
        <router-link class="routerLink" to="/resources/blog" v-if="!tag">{{Language.knowledgeDetail.Knowledge_of_inspection_and_testing}}</router-link> >
        <span @click="reload" style="color: var(--theme-color1);cursor: pointer" v-if="Language_type == 1">{{ info.title }}</span>
        <span @click="reload" style="color: var(--theme-color1);cursor: pointer" v-if="Language_type != 1">{{ info.title_en }}</span>
      </div>
      <H1 class="title" v-if="Language_type == 1">{{ info.title }}</H1>
      <H1 class="title" v-if="Language_type != 1">{{ info.title_en }}</H1>

      <div class="date">{{ info.date }}</div>

      <div class="content" v-html="info.content" v-if="Language_type == 1">

      </div>

      <div class="content" v-html="info.content_en" v-if="Language_type != 1">

      </div>

      <div class="icon-list">
        <div style="width: 40px; height: 40px;" @click="toweb(1)">
          <img class="imgStyle" src="../assets/img/knowledgeDetail/facebook.png" alt/>
        </div>
        <div style="width: 40px; height: 40px;" @click="toweb(2)">
          <img class="imgStyle" src="../assets/img/knowledgeDetail/twitter.png" alt/>
        </div>
        <div style="width: 40px; height: 40px;" @click="toweb(3)">
          <img class="imgStyle" src="../assets/img/knowledgeDetail/ins.png" alt/>
        </div>
        <div style="width: 40px; height: 40px;" @click="toweb(4)">
          <img class="imgStyle" src="../assets/img/knowledgeDetail/youtube.png" alt/>
        </div>
      </div>
      <div class="about-row" v-if="tag">
        <div class="about-article">{{ Language.knowledgeDetail.Related_articles }}</div>
<!--        <div class="about-more">-->
<!--          <div class="arrow"></div>-->
<!--          <div>查看更多</div>-->
<!--        </div>-->
      </div>
      <div class="list" v-if="tag">
        <div class="list-item" v-for="(item, index) in knowledgeList" :key="index" @click="toknowledgeDetail(item)">
          <div class="list-img">
            <img class="imgStyle" style="object-fit: cover" :src="$imgurl + '/' + item.cover_image" :alt="item.title" />
          </div>
          <div class="list-content">
            <div class="content-title">{{ item.title }}</div>
            <div class="divider"></div>
            <div class="content-date">{{ item.upload_date }}</div>
            <div class="content-abstract">{{ item.brief_content }}</div>
          </div>
        </div>
      </div>

      <div class="about-row" v-if="!tag">
        <div class="about-article">相关文章</div>
<!--        <div class="about-more">-->
<!--          <div class="arrow"></div>-->
<!--          <div>查看更多</div>-->
<!--        </div>-->
      </div>
      <div class="list" v-if="!tag">
        <div class="list-item" v-for="(item, index) in knowledgeList" :key="index" @click="toknowledgeDetail2(item)">
          <div class="list-img">
            <img class="imgStyle" style="object-fit: cover" :src="$imgurl + '/' + item.cover_image" :alt="item.title" />
          </div>
          <div class="list-content">
            <div class="content-title" v-if="Language_type == 1">{{ item.title }}</div>
            <div class="content-title" v-if="Language_type != 1">{{ item.title_en }}</div>
            <div class="divider"></div>
            <div class="content-date">{{ item.upload_date }}</div>
            <div class="content-abstract" v-if="Language_type == 1">{{ item.brief_content }}</div>
            <div class="content-abstract" v-if="Language_type != 1">{{ item.brief_content_en }}</div>
          </div>
        </div>
      </div>
    </div>
    <Myfoot @emitinfo="emitinfo"></Myfoot>
  </div>
</template>
<script>
import Myhead from "@/component/myheadWhite";
import Myfoot from "@/component/myfoot";

export default {
  inject:['reload','L'],
  computed:{
    Language(){
      return this.L()
    }
  },
  components: {Myhead, Myfoot},
  data() {
    return {
      Language_type:"",
      info: "",
      knowledgeList: [
        {
          image: '',
          title: '搅动“全球买家',
          date: '2023/7/20',
          abstract: '5月20日'
        },
        {
          image: '',
          title: '搅动“全球买家+国内电商”新市场——欧凯嵘创检测科技搬迁新址“兴”起航欧凯嵘创检测科技搬迁新址“兴”起航',
          date: '2023/7/20',
          abstract: '5月20日，浙江欧凯嵘创检测科技有限公司在浙江临海市大田街道柏叶东路2508号7号楼隆重举行了搬迁典礼，加拿大标准协会、台州111111111111111111111111111'
        },
        {
          image: '',
          title: '搅动“全球买家+国内电商”新市场——欧凯嵘创检测科技搬迁新址“兴”起航',
          date: '2023/7/20',
          abstract: '5月20日，浙江欧凯嵘创检测科技有限公司在浙江临海市大田街道柏叶东路2508号7号楼隆重举行了搬迁典礼，加拿大标准协会、台州'
        }
      ],
      tag:""
    }
  },
  metaInfo(){
    return{
      title:this.info.title,
      meta:[
        {name:"title",content: this.info.url_meta_title},
        {name:"keywords",content: this.info.url_meta_keywords},
        {name:"description",content: this.info.url_meta_description}
      ]
    }
  },
  mounted() {
    this.Language_type = localStorage.getItem("Language_type")
    this.getinfo()
  },
  methods: {
    getmoreinfo(){
      this.$get("/m/indexset/knowledge", {
        category_id:this.info.category_id,
        ob: " order by rand()",
        pageNo: 1,
        pageSize:3,
      }).then(res => {
        console.log(res)
        this.knowledgeList = res.data.data
      })
    },
    getinfo(){
      console.log(this.$route)
      if (this.$route.name == 'knowledgeDetail2'){
        this.$get("/m/indexset/news", {
          key:this.$route.params.key
        }).then(res => {
          console.log(res)
          if(res.data){
            this.info = res.data.data[0]
          }
        })
        this.$get("/m/indexset/news", {
          pageNo: 1,
          pageSize:3,
        }).then(res => {
          console.log(res)
          this.knowledgeList = res.data.data
        })
        return
      }
      var data = {
        key:this.$route.params.key
      }
      if(this.$route.params.type){
        data.tag = this.$route.params.type
        this.tag = this.$route.params.type
        this.gettagknowledge()
      }else{
        this.getmoreinfo()
      }
      console.log(this.$route)
      console.log(data)
      this.$get("/m/indexset/knowledge", data).then(res => {
        console.log(res)
        if(res.data){
          this.info = res.data.data[0]
        }
      })
    },
    gettagknowledge(){
      this.$get("/m/indexset/knowledge", {
        tag:'laboratory',
        pageNo: 1,
        pageSize:3,
      }).then(res => {
        console.log(res)
        this.knowledgeList = res.data.data
      })
    },
    emitinfo(key,tag){
      var data = {
        key
      }
      if(this.$route.params.tag){
        data.tag = tag
      }
      this.$get("/m/indexset/knowledge", data).then(res => {
        console.log(res)
        if(res.data){
          this.info = res.data.data[0]
          scrollTo(0,0)
        }
      })
    },
    toknowledgeDetail(item){
      console.log(item)
      this.$router.push({
        path:"/testing/laboratory/" + item.url_key,
      })
      this.reload()
    },
    toknowledgeDetail2(item){
      console.log(item)
      this.$router.push({
        path:"/resources/blog/textile-testing-standard/" + item.url_key,
      })
      this.reload()
    },
    toweb(type){
      if (type == 1){
        location.href = 'https://www.facebook.com/profile.php?id=61557470724243'
      }
      if (type == 2){
        location.href = 'https://twitter.com/bao_chong72320'
      }
      if (type == 3){
        location.href = 'https://www.linkedin.com/company/100558411/admin/feed/posts/'
      }
      if (type == 4){
        location.href = 'https://www.youtube.com/channel/UCDfrB7sbjeShsagn4RhJasQ'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  ::v-deep img{
    max-width: 100%;
  }
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 160px 0 240px;
  margin-top: -70.5px;
  .router {
    font-size: 14px;
    color: var(--title-color);
  }

  .title {
    font-size: 42px;
    color: var(--title-color);
    text-align: center;
    margin-top: 80px;
  }

  .date {
    font-size: 14px;
    color: var(--theme-color1);
    text-align: center;
    margin-top: 16px;
  }

  .content {
    max-width: 790px;
    margin: 0 auto;
    padding: 40px 0 200px;

    .content-img {
      width: 790px;
      height: 440px;
      margin-bottom: 80px;
    }

    .content-title {
      color: var(--title-color);
      font-size: 30px;
      margin-bottom: 20px;
    }

    .context-title:nth-child(2n) {
      margin-top: 56px;
    }

    .content-text {
      color: var(--text-color);
      line-height: 28px;
      text-align: justify;
    }
  }

  .icon-list {
    display: flex;
    align-items: flex-start;
    justify-content: right;
    gap: 24px;
    padding-bottom: 24px;
    border-bottom: 2px solid #e6e5e5;
  }

  .about-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 80px;

    .about-article {
      font-size: 42px;
      color: var(--title-color);
      margin-left: 6px;
    }

    .about-more {
      display: flex;
      color: var(--theme-color1);
      margin-right: 6px;
    }

    .arrow {
      position: relative;
      display: block;
      font-weight: bold;
      text-decoration: none;
      color: var(--theme-color1);
      margin-right: 10px;
    }

    .arrow:after,
    .arrow:before {
      content: "";
      display: block;
      position: absolute;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    .arrow:before {
      top: 60%;
      right: 10px;
      width: 16px;
      height: 16px;
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-transform: translate(0, -0.5em) rotate(45deg);
      transform: translate(0, -0.5em) rotate(45deg);
      box-shadow: inset 0 0.2em 0 0 var(--theme-color1);
      border-radius: 0 0.15em 0 0;
    }

    .arrow:after {
      top: 60%;
      right: 10px;
      width: 24px;
      height: 4px;
      -webkit-transform: translate(0, -0.1em);
      transform: translate(0, -0.1em);
      background-color: var(--theme-color1);
    }
  }

  .list {
    margin-top: 56px;
    display: flex;
    //justify-content: space-between;
    flex-wrap: wrap;
    //column-gap: 30px;
    //row-gap: 80px;

    .list-item {
      width: calc(33.3% - 7px);
      margin-right: 10px;
      background-color: #ffffff;
      border-radius: 24px;

      .list-img {
        width: auto;
        height: 200px;
        object-fit: cover;
        border-radius: 24px 24px 0 0;
      }

      .list-content {
        padding: 16px 24px 24px;

        .content-title {
          font-size: 23px;
          font-weight: bold;
          color: var(--title-color);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          word-break: break-all;
          -webkit-box-orient: vertical;
          height: 100px;
        }

        .divider {
          border-top: 1px solid var(--theme-color1);
          margin: 16px 0;
        }

        .content-date {
          font-size: 14px;
          color: var(--theme-color1);
          margin-bottom: 8px;
        }

        .content-abstract {
          font-size: 14px;
          color: var(--text-color);
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          word-break: break-all;
          -webkit-box-orient: vertical;
        }
      }
    }

    .list-item:nth-child(3n){
      margin-right: 0px;
    }

  }
}
@media (min-width: 0px) and (max-width: 1200px){
  .container{
    padding: 160px 20px 240px;
    .list{
      .list-item{
        width: 49%;
      }
    }
  }
}
@media (min-width: 0px) and (max-width: 700px){
  .container{
    padding: 160px 0 50px;
    .list{
      .list-item{
        width: 100%;
        margin-bottom: 20px;
        border-radius:0px
      }
    }

    .router{
      padding: 0px 20px;
    }

    .title{
      padding: 0px 20px;
    }
    .content{
      padding: 0px 20px;
    }
    .icon-list{
      padding: 0px 20px 20px;
    }
    .about-row{
      padding: 0px 20px;
    }
  }
}
</style>
